'use client'

import { useContext } from 'react'
import { Cell } from '@vinted/web-ui'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'

import ItemPageGalleryPlugin from '../plugins/Gallery'
import { ItemPageGalleryPluginModel } from '../plugins/Gallery/types'
import ItemPageBreadcrumbsPlugin from '../plugins/Breadcrumbs/ItemPageBreadcrumbsPlugin'
import ItemPageAlertsPluginDeprecated from '../plugins/Alerts/ItemPageAlertsPluginDeprecated'
import { ItemPageBreadcrumbsPluginModel } from '../plugins/Breadcrumbs/types'
import { ItemPageAlertsPluginModelDeprecated } from '../plugins/Alerts/types'
import ItemPageReportButtonPlugin from '../plugins/ReportButton'
import { ItemPageReportButtonPluginModel } from '../plugins/ReportButton/types'
import { PluginsContext } from '../containers/PluginsProvider'
import Plugin from '../plugins/Plugin'
import { PluginName } from '../types'

type Props = {
  isUserAdmin: boolean
  galleryPluginModel: ItemPageGalleryPluginModel | undefined
  breadcrumbsPluginModel: ItemPageBreadcrumbsPluginModel | undefined
  alertPluginModel: ItemPageAlertsPluginModelDeprecated | undefined
  reportButtonPluginModel: ItemPageReportButtonPluginModel | undefined
}

const ContentSection = ({
  isUserAdmin,
  galleryPluginModel,
  breadcrumbsPluginModel,
  alertPluginModel,
  reportButtonPluginModel,
}: Props) => {
  const { plugins: serverSidePlugins } = useContext(PluginsContext)
  const isBedaPluginAlertsEnabled = useFeatureSwitch('beda_plugin_alerts_web')
  const isBedaPluginBreadcrumbsEnabled = useFeatureSwitch('beda_plugin_breadcrumbs_web')

  const body = isBedaPluginBreadcrumbsEnabled ? (
    <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Breadcrumbs)} />
  ) : (
    breadcrumbsPluginModel && <ItemPageBreadcrumbsPlugin {...breadcrumbsPluginModel} />
  )

  return (
    <>
      {isBedaPluginAlertsEnabled ? (
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Alert)} />
      ) : (
        alertPluginModel && (
          <ItemPageAlertsPluginDeprecated data={alertPluginModel} isUserAdmin={isUserAdmin} />
        )
      )}
      {galleryPluginModel && <ItemPageGalleryPlugin data={galleryPluginModel} />}
      <Cell
        theme="transparent"
        styling={Cell.Styling.Tight}
        body={body}
        suffix={<ItemPageReportButtonPlugin data={reportButtonPluginModel} />}
      />
    </>
  )
}

export default ContentSection
