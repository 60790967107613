'use client'

import { useEffect, useRef } from 'react'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useFetch from 'hooks/useFetch'
import useLocation from 'hooks/useLocation'
import useTracking from 'hooks/useTracking'
import useBreakpoint from 'hooks/useBreakpoint'
import useAbTest from 'hooks/useAbTest'
import useSession from 'hooks/useSession'
import useAbTestExposeTracking from 'hooks/useAbTestExposeTracking'

import { getReferrerPath } from '_libs/utils/url'
import { extractItemIdFromUrl } from '_libs/utils/item'
import { getSearchSessionData } from '_libs/utils/search'
import { viewItemEvent, viewOwnItemEvent } from '_libs/common/event-tracker/events'

import { transformProfileUserInfoResponse } from 'data/api/transformers/response'
import { getProfileUserInfo } from 'data/api'

import { ItemDetailsDto, ItemPagePluginEntityDto } from 'types/dtos'
import { ItemShippingDetailsModel } from 'types/models/shipping-option'
import { EscrowFeesModel } from 'types/models/escrow-fees'

import ErrorBoundary from 'components/ErrorBoundary'
import { AbTestVariant } from 'constants/abtest'

import ContentSection from './ContentSection'
import Sidebar, { SidebarRedesigned } from './Sidebar'
import Feed from './Feed'
import { useItemPlugins } from './hooks'
import ShippingDetailsProvider from './containers/ShippingDetailsProvider'
import EscrowFeesProvider from './containers/EscrowFeesProvider'
import ItemPageAdminSectionPlugin from './plugins/AdminSection/ItemPageAdminSectionPlugin'
import ItemStickyButtons from './ItemStickyButtons'
import {
  incrementPageLoadCounter,
  logItemPageError,
  observePageLoadTime,
} from './utils/observability'
import ErrorModal from './common/ErrorModal'
import PluginsProvider from './containers/PluginsProvider'
import { PluginName } from './types'
import Plugin from './plugins/Plugin'

type Props = {
  itemDto: ItemDetailsDto
  shippingDetails?: ItemShippingDetailsModel | null
  escrowFees?: EscrowFeesModel | null
  plugins?: Array<ItemPagePluginEntityDto>
}

const Item = ({ itemDto, shippingDetails, plugins: serverSidePlugins, escrowFees }: Props) => {
  const pageLoadStartTime = useRef<number>(performance.now())
  const isLoadTimeObserved = useRef(false)

  const { fetch: fetchProfileUserInfo, transformedData: profileUserInfo } = useFetch(
    getProfileUserInfo,
    transformProfileUserInfoResponse,
  )

  const { track } = useTracking()
  const { relativeUrl } = useLocation()
  const breakpoint = useBreakpoint()
  const isBedaPluginAdminEnabled = useFeatureSwitch('beda_plugin_admin_web')
  const plugins = useItemPlugins(itemDto)

  useAbTest({
    abTestName: 'vgo_first_time_usage_incentivisation_v1',
    shouldTrackExpose: true,
  })

  useAbTest({
    abTestName: 'dd_tx_long_text_behavior_web',
    shouldTrackExpose: true,
    shouldTrackOncePerSessionDay: true,
  })

  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q4' })?.variant === AbTestVariant.On
  const isItemPageRedesignEnabled =
    useAbTest({
      abTestName: 'item_page_redesign_web',
      shouldTrackExpose: buyerHoldoutAbTestEnabled,
      shouldTrackOncePerSessionDay: true,
    })?.variant === AbTestVariant.On && buyerHoldoutAbTestEnabled

  const contentCssClass = isItemPageRedesignEnabled
    ? 'item-page-content-container'
    : 'content-container'
  const sidebarCssClass = isItemPageRedesignEnabled
    ? 'item-page-sidebar-container u-position-relative'
    : 'sidebar-container u-position-relative'

  const currentUserId = useSession().user?.id

  const itemId = extractItemIdFromUrl(relativeUrl) || itemDto?.id
  const { searchSessionId, globalCatalogBrowseSessionId } = getSearchSessionData()

  const holidayModeRedirectAbTest = useAbTest({ abTestName: 'holiday_mode_redirect' })
  const { trackExpose: trackUserOnHolidayExpose } = useAbTestExposeTracking({
    abTestName: 'holiday_mode_redirect',
  })

  useEffect(() => {
    incrementPageLoadCounter('initiated')
  }, [])

  useEffect(() => {
    if (!itemId) return

    const userId = itemDto?.user.id

    if (!userId) return

    fetchProfileUserInfo(userId)
  }, [fetchProfileUserInfo, itemId, itemDto?.user.id])

  useEffect(() => {
    const trackUserOnHoliday =
      holidayModeRedirectAbTest?.variant === 'off' && itemDto?.user.is_on_holiday

    if (!trackUserOnHoliday) return

    trackUserOnHolidayExpose()
  }, [holidayModeRedirectAbTest, itemDto?.user.is_on_holiday, trackUserOnHolidayExpose])

  useEffect(() => {
    if (!itemDto?.id) return

    const referrerPath = getReferrerPath()

    if (currentUserId === itemDto?.user.id) {
      track(
        viewOwnItemEvent({
          referrerPath,
          searchSessionId,
          itemId: itemDto.id,
          ownerId: itemDto.user.id,
        }),
      )

      return
    }

    track(
      viewItemEvent({
        referrerPath,
        searchSessionId,
        globalCatalogBrowseSessionId,
        itemId: itemDto.id,
        ownerId: itemDto.user.id,
      }),
    )
  }, [
    track,
    currentUserId,
    searchSessionId,
    globalCatalogBrowseSessionId,
    itemDto?.id,
    itemDto?.user.id,
  ])

  useEffect(() => {
    if (isLoadTimeObserved.current) return

    const observeLoadEnd = (state: 'succeeded' | 'failed') => {
      const itemLoadTime = performance.now() - pageLoadStartTime.current

      observePageLoadTime(state, itemLoadTime)
      incrementPageLoadCounter(state)

      isLoadTimeObserved.current = true
    }

    if (plugins && Object.values(plugins).every(plugin => plugin !== null)) {
      observeLoadEnd('succeeded')
    } else {
      observeLoadEnd('failed')
    }
  }, [plugins])

  const renderContent = () => {
    if (!itemDto) return null

    const isSupportAgent = profileUserInfo?.showSupportFunctionality || false
    const feedProps: ComponentProps<typeof Feed> = {
      closetData: plugins?.closet ?? undefined,
      similarItemsData: plugins?.similarItems ?? undefined,
      shopBundlesBlockData: plugins?.shopBundlesBlock ?? undefined,
      closetTitleData: plugins?.closetTitle ?? undefined,
    }
    const contentProps: ComponentProps<typeof ContentSection> = {
      isUserAdmin: isSupportAgent,
      galleryPluginModel: plugins?.gallery ?? undefined,
      breadcrumbsPluginModel: plugins?.breadcrumbs ?? undefined,
      alertPluginModel: plugins?.alerts ?? undefined,
      reportButtonPluginModel: plugins?.reportButton ?? undefined,
    }

    return (
      <main className="item-information">
        {isBedaPluginAdminEnabled ? (
          <Plugin
            data={serverSidePlugins?.find(plugin => plugin.name === PluginName.AdminSection)}
          />
        ) : (
          isSupportAgent &&
          plugins?.adminSection && <ItemPageAdminSectionPlugin {...plugins.adminSection} />
        )}
        {breakpoint.portables && (
          <section
            id="content-mobiles"
            className={contentCssClass}
            data-testid="item-mobiles-only-container"
          >
            <ContentSection {...contentProps} />
            {isItemPageRedesignEnabled ? (
              <SidebarRedesigned item={itemDto} />
            ) : (
              <Sidebar item={itemDto} />
            )}
            <Feed {...feedProps} />
          </section>
        )}
        {breakpoint.desktops && (
          <div
            className="u-ui-margin-top-large content-wrapper-flex"
            data-testid="item-desktops-only-container"
          >
            <section id="content" className={contentCssClass}>
              <div className="body-content">
                <ContentSection {...contentProps} />
                <Feed {...feedProps} />
              </div>
            </section>
            <aside id="sidebar" className={sidebarCssClass}>
              {isItemPageRedesignEnabled ? (
                <SidebarRedesigned item={itemDto} />
              ) : (
                <Sidebar item={itemDto} />
              )}
            </aside>
          </div>
        )}
        <ItemStickyButtons item={itemDto} />
      </main>
    )
  }

  return (
    <PluginsProvider plugins={serverSidePlugins} itemId={itemId}>
      <ShippingDetailsProvider shippingDetails={shippingDetails}>
        <EscrowFeesProvider escrowFees={escrowFees}>{renderContent()}</EscrowFeesProvider>
      </ShippingDetailsProvider>
    </PluginsProvider>
  )
}

const WrappedItem = (props: ComponentProps<typeof Item>) => (
  <ErrorBoundary FallbackComponent={ErrorModal} preventLog onError={logItemPageError}>
    <Item {...props} />
  </ErrorBoundary>
)

export default WrappedItem
