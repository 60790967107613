import { ItemDetailsDto } from 'types/dtos'

import { ItemPagePerformanceBlockModel } from '../types'

export const getItemPagePerformanceBlockPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPagePerformanceBlockModel => ({
  userId: itemDto.user.id,
  itemId: itemDto.id,
  areStatsVisible: itemDto.stats_visible,
  isBumped: itemDto.promoted,
})
