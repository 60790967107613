'use client'

import { useCallback, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import useTracking from 'hooks/useTracking'
import userSession from 'hooks/useSession'

import { BuyerOfferRequestDialog } from 'components/Offers/BuyerOfferRequestDialog'
import { OfferFormModel, OfferFormField } from 'components/Offers/types'
import { BuyerOfferRequestsExceededModal } from 'components/Offers/BuyerOfferRequestsExceededModal'

import { navigateToPage } from '_libs/utils/window'
import { formatCurrencyAmount } from '_libs/utils/formatString'
import { startConversationEvent } from '_libs/common/event-tracker/events'

import { UiState } from 'constants/ui'
import { Screen } from 'constants/tracking/screens'
import { CONVERSATION_URL } from 'constants/routes/inbox'
import { CUSTOM_OFFER_PRICE_OPTION_INDEX } from 'constants/conversation'

import { getScreenName } from 'state/screen/selectors'
import * as conversationSelectors from 'state/conversation/selectors'
import { actions as conversationActions } from 'state/conversation/slice'

type Props = {
  isOpen: boolean
  currency: string
  onClose: () => void
  itemInfo: {
    itemId: string
    itemIds: Array<number>
    itemTitle?: string
    itemThumbnailUrl: string | null
    itemPrice: string
    sellerId: number
  }
}

// TODO: Refactor this component to be used with single and multiple items
const ItemBuyerOfferModal = ({
  isOpen,
  itemInfo: { itemId, itemIds, itemTitle, itemThumbnailUrl, itemPrice, sellerId },
  onClose,
  currency,
}: Props) => {
  const formMethods = useForm<OfferFormModel>()
  const { reset, setError, getValues, handleSubmit } = formMethods

  const [isSubmitting, setIsSubmitting] = useState(false)

  const dispatch = useDispatch()
  const { track } = useTracking()
  const { locale } = useIntl()
  const userId = userSession().user?.id

  const conversation = useSelector(conversationSelectors.getConversation)
  const createOfferUi = useSelector(conversationSelectors.getCreateOfferUi)
  const offerRequestOptions = useSelector(conversationSelectors.getOfferRequestOptions)
  const offerPriceSuggestions = useSelector(conversationSelectors.getOfferRequestPriceSuggestions)
  const selectedOfferPriceSuggestion = useSelector(
    conversationSelectors.getSelectedOfferPriceSuggestionOption,
  )
  const transaction = useSelector(conversationSelectors.getTransaction)
  const screenName = useSelector(getScreenName)

  const transactionId = conversation?.transactionId || transaction?.id

  const handleError = useCallback(
    (message: string) => {
      setError(OfferFormField.Offer, { type: 'manual', message })
      setIsSubmitting(false)
    },
    [setError],
  )

  useEffect(() => {
    if (!isOpen) return

    reset({
      [OfferFormField.Offer]: '',
    })
  }, [reset, isOpen, offerPriceSuggestions])

  useEffect(() => {
    dispatch(
      conversationActions.getOfferRequestOptionsRequest({
        itemPrice: {
          amount: itemPrice,
          currencyCode: currency,
        },
        sellerId,
      }),
    )
  }, [dispatch, currency, itemPrice, sellerId])

  const isBundlingScreen = screenName === Screen.Bundling

  useEffect(() => {
    if (isBundlingScreen) return

    if (createOfferUi.uiState === UiState.Success && conversation && userId) {
      track(startConversationEvent({ userId, itemId }))

      navigateToPage(CONVERSATION_URL(conversation.id))
    }

    if (createOfferUi.uiState === UiState.Failure && createOfferUi.error) {
      handleError(createOfferUi.error)
    }
  }, [userId, itemId, track, handleError, conversation, createOfferUi, isBundlingScreen])

  useEffect(() => {
    if (!isBundlingScreen) return

    if (createOfferUi.uiState === UiState.Success && transaction?.conversationId && userId) {
      navigateToPage(CONVERSATION_URL(transaction.conversationId))
    }

    if (createOfferUi.uiState === UiState.Failure && createOfferUi.error) {
      handleError(createOfferUi.error)
    }
  }, [
    userId,
    handleError,
    createOfferUi.error,
    createOfferUi.uiState,
    transaction?.conversationId,
    isBundlingScreen,
  ])

  useEffect(() => {
    if (!isOpen || !offerPriceSuggestions) return undefined

    return () => {
      dispatch(conversationActions.resetSelectedOfferPriceSuggestionOption())
    }
  }, [dispatch, isOpen, offerPriceSuggestions])

  function handleFormSubmit() {
    if (!transactionId) return

    setIsSubmitting(true)

    const formattedOfferValue = getValues().offer.replace(',', '.')

    const selectedOfferPriceSuggestionIndex = offerPriceSuggestions?.findIndex(
      suggestion => suggestion.price === selectedOfferPriceSuggestion?.suggestion?.price,
    )
    const optionIndex = selectedOfferPriceSuggestion?.isCustom
      ? CUSTOM_OFFER_PRICE_OPTION_INDEX
      : selectedOfferPriceSuggestionIndex

    dispatch(
      conversationActions.createOfferRequest({
        currency,
        isBuyer: true,
        offerPrice: formattedOfferValue,
        currentPrice: itemPrice,
        transactionId,
        offerSuggestionOptionIndex: optionIndex,
      }),
    )
  }

  function handleOnClose() {
    if (isSubmitting) return

    onClose()
  }

  const remainingOffers = offerRequestOptions?.remainingOfferCount

  if (remainingOffers === 0) {
    return (
      <BuyerOfferRequestsExceededModal
        show={isOpen}
        onClose={onClose}
        maxOfferCount={offerRequestOptions?.maxOfferCount}
      />
    )
  }

  if (!offerRequestOptions || !remainingOffers) return null

  const { feesReductionThreshold } = offerRequestOptions

  const thresholdPrice = feesReductionThreshold
    ? formatCurrencyAmount(feesReductionThreshold, locale)
    : null

  return (
    <FormProvider {...formMethods}>
      <BuyerOfferRequestDialog
        show={isOpen}
        isSubmitting={isSubmitting}
        minItemPrice={offerRequestOptions.minPrice}
        maxItemPrice={offerRequestOptions.maxPrice}
        maxDiscount={offerRequestOptions.maxDiscount}
        itemPrice={{
          amount: itemPrice,
          currencyCode: currency,
        }}
        itemId={Number(itemId)}
        itemTitle={itemTitle}
        itemThumbnailUrl={itemThumbnailUrl}
        orderItemIds={itemIds}
        thresholdPrice={thresholdPrice}
        remainingOffers={remainingOffers}
        offerPriceSuggestions={offerPriceSuggestions}
        onSubmit={handleSubmit(handleFormSubmit)}
        onClose={handleOnClose}
      />
    </FormProvider>
  )
}

export default ItemBuyerOfferModal
