import { ClosetItemDto } from 'types/dtos'
import { ProductItemModel } from 'types/models'
import { getItemThumbnail } from 'data/utils/item'
import { ItemThumbnailSize } from 'constants/images'

import { transformItemPhotoDtos } from './item'
import { getDominantColor, transformPartialUserDtoToProductItemUser } from './product-item'
import { transformCurrencyAmountDto } from './currency-amount'
import { transformIconBadges } from './icon-badges'
import { transformItemBoxDto } from './item-box'

export const transformClosetItemDtoToProductItem = (
  closetItem: ClosetItemDto,
): ProductItemModel => ({
  id: closetItem.id,
  title: closetItem.title,
  url: closetItem.url,
  photos: closetItem.photos ? transformItemPhotoDtos(closetItem.photos) : [],
  viewCount: closetItem.view_count,
  favouriteCount: closetItem.favourite_count,
  sizeTitle: closetItem.size_title || '',
  user: transformPartialUserDtoToProductItemUser(closetItem.user),
  price: transformCurrencyAmountDto(closetItem.price),
  thumbnailUrl: getItemThumbnail(closetItem.photos, ItemThumbnailSize.Large),
  dominantColor: getDominantColor(closetItem.photos),
  badge: closetItem.badge,
  isPromoted: closetItem.promoted,
  isDraft: closetItem.is_draft,
  isFavourite: closetItem.is_favourite,
  iconBadges: transformIconBadges(closetItem.icon_badges),
  priceWithDiscount: closetItem.discount_price
    ? transformCurrencyAmountDto(closetItem.discount_price)
    : null,
  serviceFee: closetItem.service_fee ? transformCurrencyAmountDto(closetItem.service_fee) : null,
  totalItemPrice: closetItem.total_item_price
    ? transformCurrencyAmountDto(closetItem.total_item_price)
    : undefined,
  itemBox: closetItem.item_box && transformItemBoxDto(closetItem.item_box),
  brandTitle: closetItem.brand_dto?.title || '',
})
