import { ItemDetailsDto, ItemPageAdminSectionDto } from 'types/dtos'

import { ItemPageAdminSectionPluginModel } from './types'

export const getItemPageAdminSectionPluginModelDeprecated = (
  itemDto: ItemDetailsDto,
): ItemPageAdminSectionPluginModel => ({
  itemId: itemDto.id,
})

export const getItemPageAdminSectionPluginModel = (
  pluginData: ItemPageAdminSectionDto,
): ItemPageAdminSectionPluginModel => ({
  itemId: pluginData.item_id,
})
