import { ItemDetailsDto } from 'types/dtos'

import { ItemPageMarkAsReservedButtonPluginModel } from './types'

export const getItemPageMarkAsReservedButtonPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageMarkAsReservedButtonPluginModel => ({
  itemId: itemDto.id,
  isItemReserved: itemDto.is_reserved,
  canReserve: itemDto.can_reserve,
  transactionPermitted: itemDto.transaction_permitted,
  userId: itemDto.user.id,
})
