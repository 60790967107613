import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import useCookie from '_libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from '_libs/common/cookie-manager/cookies-data'

const useHelpCenterSessionTracking = () => {
  const cookies = useCookie()

  const [helpCenterSessionId, setHelpCenterSessionId] = useState(
    cookies.get(cookiesDataByName.help_center_session_id) || null,
  )

  useEffect(() => {
    if (helpCenterSessionId) return

    const newSessionId = uuidv4()

    cookies.set(cookiesDataByName.help_center_session_id, newSessionId)
    setHelpCenterSessionId(newSessionId)
  }, [cookies, helpCenterSessionId])

  return { helpCenterSessionId }
}

export default useHelpCenterSessionTracking
