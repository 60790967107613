'use client'

import { useEffect } from 'react'

import useFetch from 'hooks/useFetch'
import BreadcrumbsComponent from 'components/Breadcrumbs'
import { getItemBreadcrumbs } from 'data/api'
import { urlWithParams } from '_libs/utils/url'
import { ROOT_URL } from 'constants/routes'

import { ItemPageBreadcrumbsPluginModel } from './types'

const getUrlWithParams = (url: string) => {
  if (url === ROOT_URL) return url

  return urlWithParams(url, { referrer: 'item-crumbs' })
}

const ItemPageBreadcrumbsPlugin = (breadcrumbsData: ItemPageBreadcrumbsPluginModel) => {
  const { fetch: fetchBreadcrumbs, data } = useFetch(getItemBreadcrumbs)

  useEffect(() => {
    const { catalogId, brandId } = breadcrumbsData

    fetchBreadcrumbs({ catalogId, brandId })
  }, [fetchBreadcrumbs, breadcrumbsData])

  if (!data?.breadcrumbs) return null

  return (
    <div className="u-ui-margin-top-medium">
      <BreadcrumbsComponent truncate>
        {data.breadcrumbs.map(breadcrumb => (
          <BreadcrumbsComponent.Item
            key={breadcrumb.title}
            url={getUrlWithParams(breadcrumb.url)}
            title={breadcrumb.title}
          />
        ))}
      </BreadcrumbsComponent>
    </div>
  )
}

export default ItemPageBreadcrumbsPlugin
