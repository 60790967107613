import { ItemDetailsDto } from 'types/dtos'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'

import { ItemPageGalleryPluginModel } from './types'

export const getItemPageGalleryPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageGalleryPluginModel => ({
  id: itemDto.id,
  title: itemDto.title,
  photos: itemDto.photos,
  offlineVerification: itemDto.offline_verification,
  ownerId: itemDto.user.id,
  verificationFee: itemDto.offline_verification_fee
    ? transformCurrencyAmountDto(itemDto.offline_verification_fee)
    : undefined,
  isFavourite: itemDto.is_favourite,
  itemId: itemDto.id,
  favouriteCount: itemDto.favourite_count,
})
