import { useIntl } from 'react-intl'

import useTranslate from 'hooks/useTranslate'

import { formatCurrencyAmount } from '_libs/utils/formatString'
import { ItemShippingDetailsModel } from 'types/models/shipping-option'

import useSession from './useSession'

type UseShippingPriceProps = {
  shippingDetails?: ItemShippingDetailsModel
  trimTranslation?: boolean
}

function useShippingPrice({ shippingDetails, trimTranslation = false }: UseShippingPriceProps) {
  const translate = useTranslate('item.shipment_options')

  const { locale } = useIntl()
  const { user } = useSession()
  const city = user?.city

  if (!shippingDetails) return null

  const { price, isPickupOnly, isFreeShipping, areMultipleShippingOptionsAvailable } =
    shippingDetails

  const formattedPrice = formatCurrencyAmount(price, locale)

  if (areMultipleShippingOptionsAvailable) {
    if (isFreeShipping) return translate('free_shipping')
    if (trimTranslation) return translate('price_from', { value: formattedPrice }).toLowerCase()

    return translate('shipping_from', {
      value: formattedPrice,
    })
  }

  if (isPickupOnly) {
    if (!city) return translate('no_shipping')

    return translate('pickup_at', { location: city })
  }

  if (isFreeShipping) return translate('free_shipping')
  if (trimTranslation) return formattedPrice

  return `${translate('shipping')} ${formattedPrice}`
}

export default useShippingPrice
