import { ItemDetailsDto } from 'types/dtos'

import { ItemPageEditActionModel } from '../types'

export const getItemPageEditActionPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageEditActionModel => ({
  id: itemDto.id,
  userId: itemDto.user.id,
  canEdit: itemDto.can_edit || false,
})
