import { ItemDetailsDto, ItemPageAlertDto } from 'types/dtos'
import { transformItemAlertDto } from 'data/transformers/item-alert'

import { ItemPageAlertsPluginModel, ItemPageAlertsPluginModelDeprecated } from './types'

export const getItemAlertsPluginModelDeprecated = (
  itemDto: ItemDetailsDto,
): ItemPageAlertsPluginModelDeprecated => ({
  itemId: itemDto.id,
  itemUserId: itemDto.user.id,
  itemAlert: itemDto.item_alert,
})

export const getItemPageItemAlertsPluginModel = (
  data: ItemPageAlertDto,
): ItemPageAlertsPluginModel => ({
  itemId: data.item_id,
  itemAlert: transformItemAlertDto(data.item_alert),
})
