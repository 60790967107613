'use client'

import { useContext } from 'react'
import { Card, Cell, Image, Spacer, Text } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'
import { useEnableItemPageRedesign } from 'pages/Item/hooks'

import { ShippingDetailsContext } from '../../containers/ShippingDetailsProvider'
import { EscrowFeesContext } from '../../containers/EscrowFeesProvider'

import { ItemPageBuyerProtectionInfoPluginModel } from './types'

type Props = {
  data: ItemPageBuyerProtectionInfoPluginModel
}

const ItemPageBuyerProtectionInfoPlugin = ({ data }: Props) => {
  const { user } = useSession()
  const translate = useTranslate('item.service_fee')
  const asset = useAsset('/assets/buyer-protection')
  const { shippingDetails } = useContext(ShippingDetailsContext)
  const { escrowFees } = useContext(EscrowFeesContext)
  const isItemPageRedesignEnabled = useEnableItemPageRedesign()

  const { isSellerBusiness, itemUserId } = data
  const isViewingSelf = user?.id === itemUserId

  if (!shippingDetails || isViewingSelf || !escrowFees?.buyerProtection?.finalPrice) return null

  const title = isSellerBusiness ? translate('business.title') : translate('title')
  const description = isSellerBusiness
    ? translate('business.description')
    : translate('description')
  const getImageName = isSellerBusiness
    ? 'buyer-protection-pro-shield.svg'
    : 'buyer-protection-shield.svg'
  const shieldImagePath = asset(getImageName, { theme: { dark: `dark/${getImageName}` } })

  const renderContent = () => (
    <>
      <Cell
        styling={Cell.Styling.Tight}
        prefix={
          <Image
            src={shieldImagePath}
            size={Image.Size.Medium}
            testId="item-service-fee-shield-image"
          />
        }
        title={
          <Text
            as="h2"
            text={title}
            theme="amplified"
            type={Text.Type.Title}
            testId="item-service-fee-title"
          />
        }
        body={
          <Text
            as="h3"
            text={description}
            theme="inherit"
            type={Text.Type.Subtitle}
            testId={
              isSellerBusiness ? 'item-safety-description-business' : 'item-safety-description'
            }
            html
          />
        }
      />
      {!isItemPageRedesignEnabled && <Spacer />}
    </>
  )

  return isItemPageRedesignEnabled ? (
    <Card>
      <div className="u-overflow-hidden">
        <Cell>{renderContent()}</Cell>
      </div>
    </Card>
  ) : (
    renderContent()
  )
}

export default ItemPageBuyerProtectionInfoPlugin
