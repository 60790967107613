'use client'

import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useLocation from 'hooks/useLocation'
import userSession from 'hooks/useSession'

import { clickEvent, buyerWantItemEvent } from '_libs/common/event-tracker/events'
import { getHomepageSessionIdFromUrl } from '_libs/utils/homepage'
import { getSearchSessionData } from '_libs/utils/search'
import useAuthModal from '_libs/common/auth-modal/useAuthModal'

import { Screen } from 'constants/tracking/screens'
import { ConversationInitiatorType } from 'constants/conversation'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { getConversation } from 'state/conversation/selectors'
import { actions as conversationActions } from 'state/conversation/slice'
import { useCanTransact } from 'pages/Item/hooks'

import ItemBuyerOfferModal from './ItemBuyerOfferModal'
import { ItemPageMakeOfferActionPluginModel } from './types'
import { useShowStickyButtons } from '../../hooks/useShowStickyButtons'

type Props = {
  data: ItemPageMakeOfferActionPluginModel
  isStickyButton?: boolean
}

const ItemPageMakeOfferActionPlugin = ({ data, isStickyButton = false }: Props) => {
  const {
    itemId,
    itemTitle,
    itemThumbnailUrl,
    price,
    sellerId,
    currency,
    discountedPrice,
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
    closetCountdownEndDate,
  } = data

  const dispatch = useDispatch()
  const { track } = useTracking()
  const translate = useTranslate('item.actions')
  const { searchParams } = useLocation()
  const canTransact = useCanTransact({
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
    closetCountdownEndDate,
  })
  const { openAuthModal } = useAuthModal()

  const conversation = useSelector(getConversation)
  const { user } = userSession()
  const isAuthenticated = !!user
  const currentUserId = user?.id

  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false)

  const referrerScreen = searchParams.referrer?.toString()
  const searchSessionData = getSearchSessionData()
  const searchSessionIdRef = useRef(
    referrerScreen === Screen.Catalog ? searchSessionData.searchSessionId : null,
  )

  const itemIdStringified = itemId.toString()
  const sellerIdStringified = sellerId.toString()
  const isViewingOwnItem = sellerId === currentUserId

  const showStickyButtons = useShowStickyButtons({ isViewingOwnItem })
  const isButtonHidden = isViewingOwnItem || !canTransact || showStickyButtons !== isStickyButton

  useEffect(() => {
    if (isButtonHidden) return
    if (!isOfferModalOpen) return
    if (!conversation?.transactionId) return

    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.MakeBuyerSideOffer,
        targetDetails: JSON.stringify({
          item_id: itemId,
          transaction_id: conversation.transactionId,
        }),
      }),
    )

    track(
      buyerWantItemEvent({
        itemId,
        transactionId: conversation.transactionId,
        button: 'make_offer',
        globalSearchSessionId: searchSessionData.globalSearchSessionId,
        globalCatalogBrowseSessionId: searchSessionData.globalCatalogBrowseSessionId,
        searchSessionId: searchSessionIdRef.current,
        homepageSessionId: getHomepageSessionIdFromUrl(),
      }),
    )
  }, [
    track,
    conversation,
    isOfferModalOpen,
    searchSessionData.globalSearchSessionId,
    searchSessionData.globalCatalogBrowseSessionId,
    itemId,
    isButtonHidden,
  ])

  function handleOfferModalOpen() {
    if (isAuthenticated) {
      setIsOfferModalOpen(true)

      dispatch(
        conversationActions.createItemConversationThreadRequest({
          itemId: itemIdStringified,
          sellerId: sellerIdStringified,
          initiator: ConversationInitiatorType.BuyerEntersOfferForm,
        }),
      )

      return
    }

    openAuthModal()
  }

  function handleOfferModalClose() {
    setIsOfferModalOpen(false)
  }

  if (isButtonHidden) return null

  return (
    <>
      <Button
        text={translate('offer_request')}
        onClick={handleOfferModalOpen}
        testId="item-buyer-offer-button"
      />
      {isAuthenticated && (
        <ItemBuyerOfferModal
          isOpen={isOfferModalOpen}
          itemInfo={{
            itemId: itemIdStringified,
            itemIds: [itemId],
            itemTitle,
            itemThumbnailUrl,
            itemPrice: discountedPrice?.amount || price,
            sellerId,
          }}
          currency={currency}
          onClose={handleOfferModalClose}
        />
      )}
    </>
  )
}

export default ItemPageMakeOfferActionPlugin
