'use client'

import { Cell, Image, Spacer, Text } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest'

type Props = {
  isTight?: boolean
}

const UserBadge = ({ isTight }: Props) => {
  const translate = useTranslate('user.seller_badges')
  const asset = useAsset('/assets/user-badges')

  const frequentUploadsAbTest = useAbTest({ abTestName: 'rename_star_wardrobe_badge' })
  const showFrequentUploadsBadge = frequentUploadsAbTest && frequentUploadsAbTest.variant !== 'off'

  return (
    <div className="u-flexbox u-background-white">
      {isTight && <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />}
      <Cell
        styling={isTight ? Cell.Styling.Tight : Cell.Styling.Default}
        prefix={
          <Image
            src={asset('lister-badge.svg')}
            alt=""
            size={Image.Size.Medium}
            scaling={Image.Scaling.Contain}
          />
        }
        title={
          showFrequentUploadsBadge
            ? translate('frequent_uploads.title')
            : translate('active_lister.title')
        }
        body={
          <Text
            as="h3"
            text={
              showFrequentUploadsBadge
                ? translate('frequent_uploads.body')
                : translate('active_lister.body')
            }
            type={Text.Type.Subtitle}
          />
        }
      />
    </div>
  )
}

export default UserBadge
