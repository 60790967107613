'use client'

import { Animation, Icon } from '@vinted/web-ui'
import { Heart16, Heart24, HeartFilled16, HeartFilled24 } from '@vinted/monochrome-icons'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useAsset from 'hooks/useAsset'

type Props = {
  isFavourite: boolean
  isToggled: boolean
  isIconLarge?: boolean
}

const HeartIcon = ({ isFavourite, isToggled, isIconLarge }: Props) => {
  const asset = useAsset('/assets/animations')
  const isPrideFavouriteButtonEnabled = useFeatureSwitch('pride_favourite_button')

  const showPrideHeart = isPrideFavouriteButtonEnabled && isFavourite && isToggled

  const getIconName = () => {
    if (isIconLarge) {
      return isFavourite ? HeartFilled24 : Heart24
    }

    return isFavourite ? HeartFilled16 : Heart16
  }

  if (showPrideHeart) {
    return (
      <Animation
        size={Animation.Size.Small}
        animationUrl={asset('pride-heart.json')}
        aria={{ 'aria-hidden': 'true' }}
        loop={false}
      />
    )
  }

  return (
    <Icon
      name={getIconName()}
      color={isFavourite ? Icon.Color.Warning : 'parent'}
      aria={{ 'aria-hidden': 'true' }}
    />
  )
}

export default HeartIcon
