'use client'

import { Cell, Label, Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { useEnableItemPageRedesign } from 'pages/Item/hooks'

import { ItemPageClosetTitlePluginModel } from './types'

type Props = {
  data: ItemPageClosetTitlePluginModel | undefined
}

const ItemPageClosetTitlePlugin = ({ data }: Props) => {
  const translate = useTranslate('item')
  const isItemPageRedesignEnabled = useEnableItemPageRedesign()

  if (!data) return null
  if (data.itemCount <= 1) return null

  return isItemPageRedesignEnabled ? (
    <>
      <Spacer size={Spacer.Size.XLarge} />
      <Cell
        title={
          <Text type={Text.Type.Title} as="h2">
            {translate('owner_items_title', { count: data.itemCount })}
          </Text>
        }
        styling={Cell.Styling.Tight}
      />
    </>
  ) : (
    <Label
      text={
        <Text as="h3" type={Text.Type.Subtitle}>
          {translate('owner_items_title', { count: data.itemCount })}
        </Text>
      }
    />
  )
}

export default ItemPageClosetTitlePlugin
