import {
  ItemPageSummaryDto,
  SummaryLineDto,
  SummaryNavigationalElementDto,
  SummaryTextElementDto,
} from 'types/dtos'

import {
  ItemPageSummaryPluginModel,
  SummaryLineModel,
  SummaryNavigationalElementModel,
  SummaryTextElementModel,
} from './types'

const transformSummaryElementDto = (
  element: SummaryTextElementDto | SummaryNavigationalElementDto,
): SummaryTextElementModel | SummaryNavigationalElementModel | undefined => {
  switch (element.type) {
    case 'navigational':
      return {
        type: 'navigational',
        value: element.value,
        url: element.url,
        code: element.code,
        id: element.id,
        style: element.style,
      }
    case 'text':
      return {
        type: 'text',
        value: element.value,
        style: element.style,
      }
    default:
      return undefined
  }
}

const transformSummaryLineDto = (summaryLine: SummaryLineDto): SummaryLineModel => ({
  maxLines: summaryLine.max_lines,
  elements: summaryLine.elements.map(transformSummaryElementDto).filter(Boolean),
})

export const getItemPageSummaryPluginModel = (
  summaryDto: ItemPageSummaryDto,
): ItemPageSummaryPluginModel => {
  return {
    itemId: summaryDto.item_id,
    lines: summaryDto.lines.map(transformSummaryLineDto),
  }
}
