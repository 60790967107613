import { useEffect, useRef, useState } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import { toggleBrandFollow } from 'data/api'
import { followBrandEvent } from '_libs/common/event-tracker/events'
import useAuthModal from '_libs/common/auth-modal/useAuthModal'

import useTracking from './useTracking'

type Props = {
  brandId: number
  isFollowed: boolean
}

const useFollowBrand = ({ brandId, isFollowed: initialIsFollowed }: Props) => {
  const isLoggedIn = Boolean(useSession().user)
  const [isFollowed, setIsFollowed] = useState(initialIsFollowed)
  const { track } = useTracking()
  const isLoading = useRef(false)
  const { openAuthModal } = useAuthModal()

  useEffect(() => {
    setIsFollowed(initialIsFollowed)
  }, [initialIsFollowed])

  function signIn() {
    openAuthModal()
  }

  async function toggle() {
    if (isLoading.current) return

    isLoading.current = true
    const response = await toggleBrandFollow({ brandId })
    isLoading.current = false

    if ('errors' in response) return

    const isFollowEvent = response.is_followed

    track(followBrandEvent({ brandId, isFollowEvent }))
    setIsFollowed(isFollowEvent)
  }

  return {
    isFollowed,
    toggleFollow: isLoggedIn ? toggle : signIn,
  }
}

export default useFollowBrand
