'use client'

import { Card, Divider, Spacer } from '@vinted/web-ui'

import BundleEntryBanner from 'components/BundleEntryBanner'
import { isBundlingAvailable } from '_libs/utils/bundle'
import useSession from 'hooks/useSession'
import { useEnableItemPageRedesign } from 'pages/Item/hooks'

import { ItemPageShopBundlesBlockPluginModel } from './types'

type Props = {
  data: ItemPageShopBundlesBlockPluginModel
}

const ItemPageShopBundlesBlockPlugin = ({ data }: Props) => {
  const currentUserId = useSession().user?.id
  const isItemPageRedesignEnabled = useEnableItemPageRedesign()

  const {
    canBundle,
    closetCountdownEndDate,
    itemId,
    itemUserId,
    userItemCount,
    isUserHated,
    isUserHatesYou,
    bundleDiscount,
    canBuyItem,
  } = data

  const isViewingOwnProfile = itemUserId === currentUserId

  const showPlugin = isBundlingAvailable({
    canBundle,
    totalItemCount: userItemCount,
    isCurrentUserAuthenticated: !!currentUserId,
    closetCountdownEndDate,
    hasHateFromAnySide: isUserHated || isUserHatesYou,
    isViewingOwnProfile,
  })

  if (!showPlugin) return null

  const renderContent = () => (
    <BundleEntryBanner
      itemId={itemId}
      canBuyItem={canBuyItem}
      itemUserId={itemUserId}
      bundleDiscount={bundleDiscount}
      testId="item-buy-bundle-banner"
    />
  )

  return isItemPageRedesignEnabled ? (
    <>
      <Spacer size={Spacer.Size.Large} />
      <Card>
        <div className="u-overflow-hidden">{renderContent()}</div>
      </Card>
    </>
  ) : (
    <>
      {isItemPageRedesignEnabled && <Spacer size={Spacer.Size.Large} />}
      <Divider />
      {renderContent()}
      <Spacer />
    </>
  )
}

export default ItemPageShopBundlesBlockPlugin
