import { ItemDetailsDto } from 'types/dtos'
import { transformUserBundleDiscountDto } from 'data/transformers/bundle-discount'

import { ItemPageShopBundlesBlockPluginModel } from './types'

export const getItemPageShopBundlesBlockPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageShopBundlesBlockPluginModel => {
  return {
    itemId: itemDto.id,
    itemUserId: itemDto.user.id,
    canBundle: itemDto.can_bundle,
    userItemCount: itemDto.user.item_count,
    isUserHated: itemDto.user.is_hated,
    isUserHatesYou: itemDto.user.hates_you,
    canBuyItem: itemDto.can_buy,
    closetCountdownEndDate: itemDto.user.closet_countdown_end_date,
    bundleDiscount: itemDto.user.bundle_discount
      ? transformUserBundleDiscountDto(itemDto.user.bundle_discount)
      : null,
  }
}
