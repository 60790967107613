'use client'

import { useEffect } from 'react'
import { Card } from '@vinted/web-ui'

import useSession from 'hooks/useSession'
import useFetch from 'hooks/useFetch'

import ItemPerformanceEmptyState from 'components/ItemPerformance/ItemPerformanceEmptyState'
import ItemPerformance from 'components/ItemPerformance'

import { getItemPerformance } from 'data/api'
import { transformItemPerformanceResponse } from 'data/api/transformers/response'

import { useEnableItemPageRedesign } from '../../hooks/useEnableItemPageRedesign'
import { ItemPagePerformanceBlockModel } from './types'

type Props = {
  performanceBlockPluginModel: ItemPagePerformanceBlockModel | undefined
}

const ItemPagePerformanceBlockPlugin = ({ performanceBlockPluginModel }: Props) => {
  const { user: currentUser } = useSession()
  const isItemPageRedesignEnabled = useEnableItemPageRedesign()
  const {
    fetch: fetchItemPerformance,
    transformedData: performance,
    isLoading: isPerformanceLoading,
  } = useFetch(getItemPerformance, transformItemPerformanceResponse)

  const itemId = performanceBlockPluginModel?.itemId
  const userId = performanceBlockPluginModel?.userId
  const isViewingSelf = userId && currentUser && currentUser.id === userId

  useEffect(() => {
    if (!userId || !itemId) return

    fetchItemPerformance(itemId)
  }, [itemId, userId, fetchItemPerformance])

  if (!isViewingSelf || !performanceBlockPluginModel?.areStatsVisible) return null

  if (!performance || isPerformanceLoading) return <ItemPerformanceEmptyState />

  const { isBumped } = performanceBlockPluginModel

  return isItemPageRedesignEnabled ? (
    <Card>
      <div className="u-overflow-hidden">
        <ItemPerformance performance={performance} itemPromoted={isBumped} />
      </div>
    </Card>
  ) : (
    <ItemPerformance performance={performance} itemPromoted={isBumped} />
  )
}

export default ItemPagePerformanceBlockPlugin
