import { ItemPageDescriptionDto } from 'types/dtos'

import { ItemPageDescriptionPluginModel } from '../types'

export const getItemPageDescriptionPluginModel = (
  descriptionPluginDto: ItemPageDescriptionDto,
): ItemPageDescriptionPluginModel => ({
  itemId: descriptionPluginDto.item_id,
  localization: descriptionPluginDto.localization,
  description: descriptionPluginDto.description,
  isLocalized: descriptionPluginDto.is_localized,
})
