'use client'

import { useContext } from 'react'
import { Spacer } from '@vinted/web-ui'

import ItemPageShippingPricePlugin from 'pages/Item/plugins/ShippingPrice'
import { PluginName } from 'pages/Item/types'
import { AbTestVariant } from 'constants/abtest'
import { ItemDetailsDto } from 'types/dtos'
import { Plugins } from 'pages/Item/hooks'
import { PluginsContext } from 'pages/Item/containers/PluginsProvider'
import useAbTest from 'hooks/useAbTest'

import ItemPagePricingPlugin from '../../plugins/Pricing/ItemPagePricingPlugin'
import ItemActions from '../../ItemActions'
import Plugin from '../../plugins/Plugin'

type Props = {
  item: ItemDetailsDto
  data: Plugins | null
}

const DetailsSection = ({ item, data }: Props) => {
  const { plugins: serverSidePlugins } = useContext(PluginsContext)
  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q4' })?.variant === AbTestVariant.On
  const actionsRepositioningAbTestEnabled =
    useAbTest({
      abTestName: 'action_buttons_respositioning_web',
      shouldTrackExpose: buyerHoldoutAbTestEnabled,
    })?.variant === AbTestVariant.On && buyerHoldoutAbTestEnabled

  return (
    <>
      <div className="details-list details-list--main-info">
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Summary)} />
        {data?.pricing && <ItemPagePricingPlugin data={data.pricing} />}
        {actionsRepositioningAbTestEnabled && (
          <>
            {data?.shippingPrice && (
              <>
                <Spacer size={Spacer.Size.Large} />
                <ItemPageShippingPricePlugin data={data.shippingPrice} />
              </>
            )}
            <Spacer size={Spacer.Size.Large} />
            <div className="details-list__item details-list--actions">
              <ItemActions item={item} />
              <Spacer />
            </div>
          </>
        )}
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Attributes)} />
      </div>
      <div className="details-list__info">
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Description)} />
        {!actionsRepositioningAbTestEnabled && (
          <>
            {data?.shippingPrice && <ItemPageShippingPricePlugin data={data.shippingPrice} />}
            <Spacer size={Spacer.Size.Large} />
            <div className="details-list__item details-list--actions">
              <ItemActions item={item} />
              <Spacer />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default DetailsSection
