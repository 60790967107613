import { ItemDetailsDto, ItemPageBreadcrumbsDto } from 'types/dtos'

import { ItemPageBreadcrumbsPluginModel } from './types'

export const getItemPageBreadcrumbsPluginModelDeprecated = (
  itemDto: ItemDetailsDto,
): ItemPageBreadcrumbsPluginModel => ({
  itemId: itemDto.id,
  brandId: itemDto.brand_dto?.id,
  catalogId: itemDto.catalog_id,
})

export const getItemPageBreadcrumbsPluginModel = (
  data: ItemPageBreadcrumbsDto,
): ItemPageBreadcrumbsPluginModel => ({
  itemId: data.item_id,
  brandId: data.brand_id,
  catalogId: data.catalog_id,
})
