import { ItemDetailsDto } from 'types/dtos'

import { ItemPageBuyButtonModel } from './types'

export const getItemPageBuyButtonPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageBuyButtonModel => ({
  itemId: itemDto.id.toString(),
  sellerId: itemDto.user.id.toString(),
  canBuy: itemDto.can_buy,
  canInstantBuy: itemDto.instant_buy,
  isReserved: itemDto.is_reserved,
  isHidden: itemDto.is_hidden,
  reservedForUserId: itemDto.reservation?.to_whom_id,
  closetCountdownEndDate: itemDto.user.closet_countdown_end_date || '',
})
