'use client'

import { Cell, Label, Spacer, Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'
import {
  itemPageChannelClickEvent,
  itemPageChannelViewEvent,
} from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { useEnableItemPageRedesign } from 'pages/Item/hooks'

import { ItemPageHorizontalScrollBlockHeaderPluginModel } from './types'

const ItemPageHorizontalItemsBlockHeaderPlugin = ({
  title,
  headerCta,
  pluginName,
  itemId,
  contentSource,
}: ItemPageHorizontalScrollBlockHeaderPluginModel & { pluginName: string }) => {
  const { track } = useTracking()
  const { ref } = useInView({
    onChange: inView => inView && track(itemPageChannelViewEvent({ itemId, contentSource })),
    triggerOnce: true,
  })
  const isItemPageRedesignEnabled = useEnableItemPageRedesign()

  const handleCtaClick = () => {
    track(
      itemPageChannelClickEvent({
        itemId,
        contentSource,
        target: ClickableElement.HorizontalItemsBlockHeaderCta,
      }),
    )
  }

  const renderSuffix = () =>
    headerCta && (
      <a
        href={headerCta.url}
        onClick={handleCtaClick}
        target="_blank"
        data-testid="horizontal-items-block-header-suffix"
        rel="noreferrer"
      >
        <Text
          type={Text.Type.Subtitle}
          theme="primary"
          text={headerCta.title}
          as="span"
          bold={isItemPageRedesignEnabled}
        />
      </a>
    )

  return (
    <div ref={ref}>
      {isItemPageRedesignEnabled ? (
        <>
          <Spacer size={Spacer.Size.XLarge} />
          <Cell
            title={
              <Text type={Text.Type.Title} as="h2">
                {title}
              </Text>
            }
            suffix={renderSuffix()}
            styling={Cell.Styling.Tight}
          />
        </>
      ) : (
        <Label
          text={
            <Text type={Text.Type.Subtitle} as="h2">
              {title}
            </Text>
          }
          suffix={renderSuffix()}
          testId={`item-page-${pluginName}-plugin`}
        />
      )}
    </div>
  )
}

export default ItemPageHorizontalItemsBlockHeaderPlugin
