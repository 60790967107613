import { ItemDetailsDto } from 'types/dtos'

import { ItemPageMakeOfferActionPluginModel } from './types'

export const getItemPageMakeOfferActionPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageMakeOfferActionPluginModel => ({
  itemId: itemDto.id,
  itemTitle: itemDto.title,
  itemThumbnailUrl: itemDto.photos?.[0]?.url ?? null,
  price: itemDto.price.amount,
  currency: itemDto.currency,
  sellerId: itemDto.user.id,
  discountedPrice: itemDto.discount,
  canBuy: itemDto.can_buy,
  canInstantBuy: itemDto.instant_buy,
  isReserved: itemDto.is_reserved,
  isHidden: itemDto.is_hidden,
  reservedForUserId: itemDto.reservation?.to_whom_id,
  closetCountdownEndDate: itemDto.user.closet_countdown_end_date || '',
})
