'use client'

import { Button, Card, Icon, Text } from '@vinted/web-ui'
import { VerticalDots12 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { ItemBrandDto } from 'types/dtos'
import Dropdown from 'components/Dropdown'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent, itemAttributesClickEvent } from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import useFollowBrand from 'hooks/useFollowBrand'
import { useEnableItemPageRedesign } from 'pages/Item/hooks'

type Props = {
  brandDto: ItemBrandDto
  itemId: number
  title?: string
}

const Brand = ({ brandDto, itemId, title }: Props) => {
  const translate = useTranslate('item.details')
  const { track } = useTracking()
  const isItemPageRedesignEnabled = useEnableItemPageRedesign()

  const { isFollowed, toggleFollow } = useFollowBrand({
    brandId: brandDto.id,
    isFollowed: brandDto.is_favourite,
  })

  // we don't want to show empty brand
  if (brandDto.id === 1) return null

  const brandActionTranslationKey = isFollowed ? 'unfollow' : 'follow'

  const trackClick = () => {
    track(
      clickEvent({
        target: ClickableElement.FilterByBrand,
        targetDetails: JSON.stringify({
          brand_id: brandDto.id,
        }),
        screen: Screen.Item,
      }),
    )
    track(
      itemAttributesClickEvent({
        itemId: itemId.toString(),
        attributeCode: 'brand',
        attributeValueId: brandDto.id.toString(),
      }),
    )
  }

  const renderContent = () => (
    <>
      <a
        href={brandDto.path}
        className="inverse u-disable-underline-without-hover"
        itemProp="url"
        onClick={trackClick}
      >
        <span itemProp="name">{brandDto.title}</span>
      </a>

      <div className="overflow-menu--top-right">
        <Dropdown>
          <div className="u-cursor-pointer">
            <Dropdown.Toggle>
              {({ toggle }) => (
                <button
                  title={translate('a11y.brand_menu')}
                  aria-label={translate('a11y.brand_menu')}
                  type="button"
                  onClick={toggle}
                  data-testid="item-attributes-brand-menu-button"
                >
                  <Icon
                    name={VerticalDots12}
                    color={Icon.Color.GreyscaleLevel3}
                    aria={{ 'aria-hidden': true }}
                    title={translate('a11y.brand_menu')}
                  />
                </button>
              )}
            </Dropdown.Toggle>
          </div>
          <Dropdown.Content alignment="right">
            <Card styling={Card.Styling.Lifted}>
              <Button
                text={translate(`actions.brand.${brandActionTranslationKey}`)}
                styling={Button.Styling.Flat}
                onClick={toggleFollow}
                testId="item-attributes-brand-toggle-follow-button"
              />
            </Card>
          </Dropdown.Content>
        </Dropdown>
      </div>
    </>
  )

  return (
    <div className="details-list__item u-position-relative">
      {isItemPageRedesignEnabled ? (
        <>
          <div className="details-list__item-value--redesign">
            <Text as="div" type={Text.Type.Subtitle}>
              {title || translate('brand')}
            </Text>
          </div>
          <div className="details-list__item-value--redesign">
            <Text as="div" type={Text.Type.Subtitle} bold>
              {renderContent()}
            </Text>
          </div>
        </>
      ) : (
        <>
          <div className="details-list__item-title">{title || translate('brand')}</div>
          <div className="details-list__item-value u-flexbox u-justify-content-space-between">
            {renderContent()}
          </div>
        </>
      )}
    </div>
  )
}

export default Brand
